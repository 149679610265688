<template>
    <v-app-bar id="app-bar" absolute app color="transparent" flat height="75">
        <v-btn class="mr-3" elevation="1" fab small @click="setDrawer(!drawer)">
            <v-icon v-if="value">
                mdi-view-quilt
            </v-icon>

            <v-icon v-else>
                mdi-dots-vertical
            </v-icon>
        </v-btn>

        <v-toolbar-title
            class="hidden-sm-and-down font-weight-light"
            v-text="$route.name"
        />
    </v-app-bar>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { VHover, VListItem } from 'vuetify/lib';

export default {
    name: 'DashboardCoreAppBar',

    components: {
        AppBarItem: {
            render(h) {
                return h(VHover, {
                    scopedSlots: {
                        default: ({ hover }) => {
                            return h(
                                VListItem,
                                {
                                    attrs: this.$attrs,
                                    class: {
                                        'black--text': !hover,
                                        'white--text secondary elevation-12': hover,
                                    },
                                    props: {
                                        activeClass: '',
                                        dark: hover,
                                        link: true,
                                        ...this.$attrs,
                                    },
                                },
                                this.$slots.default,
                            );
                        },
                    },
                });
            },
        },
    },

    props: {
        value: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        ...mapState(['drawer']),
    },

    methods: {
        ...mapMutations({
            setDrawer: 'SET_DRAWER',
        }),
    },
};
</script>
